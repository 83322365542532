var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.playroom && _vm.canHaveFavorites
        ? _c(
            "span",
            {
              staticClass: "favIcon",
              attrs: { "data-cy": "toggle-favorite" },
              on: {
                click: function($event) {
                  return _vm.toggleFavorite()
                }
              }
            },
            [
              _c("CIcon", {
                staticClass: "toggle-favorite",
                class: _vm.favIconStyle,
                attrs: { name: _vm.favIconName }
              })
            ],
            1
          )
        : _vm._e(),
      _c("LoginModal", {
        attrs: { show: _vm.showLoginModal },
        on: {
          "update:show": function($event) {
            _vm.showLoginModal = $event
          },
          onUpdateShow: _vm.onUpdateShow
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }