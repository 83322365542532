var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "CModal",
    {
      staticClass: "login-modal",
      attrs: {
        closeOnBackdrop: false,
        show: _vm.show,
        title: this.$t("components.LoginModal.title")
      },
      on: { "update:show": _vm.onUpdateShow },
      scopedSlots: _vm._u([
        {
          key: "footer-wrapper",
          fn: function() {
            return [_c("div")]
          },
          proxy: true
        }
      ])
    },
    [
      _c(
        "CRow",
        [
          _c(
            "CCol",
            { attrs: { md: "12" } },
            [
              _c("p", {
                directives: [
                  {
                    name: "t",
                    rawName: "v-t",
                    value: "components.LoginModal.text",
                    expression: "'components.LoginModal.text'"
                  }
                ],
                staticClass: "text-muted"
              }),
              _c(
                "CForm",
                {
                  on: {
                    submit: function($event) {
                      $event.preventDefault()
                      return _vm.handleLogin($event)
                    }
                  }
                },
                [
                  _vm.loading
                    ? _c("CElementCover", { attrs: { opacity: 0.1 } })
                    : _vm._e(),
                  _c("div", { staticClass: "form-group" }, [
                    _vm.message
                      ? _c(
                          "div",
                          {
                            staticClass: "alert alert-danger",
                            attrs: { role: "alert" }
                          },
                          [_vm._v(" " + _vm._s(_vm.message) + " ")]
                        )
                      : _vm._e()
                  ]),
                  _c("ValidatedInput", {
                    attrs: {
                      field: _vm.$v.form.username,
                      "data-cy": "email",
                      autocomplete: "email",
                      value: _vm.$v.form.username.$model,
                      translationKey: "login.email"
                    },
                    on: {
                      "update:value": function($event) {
                        return _vm.$set(_vm.$v.form.username, "$model", $event)
                      }
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "prepend-content",
                        fn: function() {
                          return [_c("CIcon", { attrs: { name: "cil-at" } })]
                        },
                        proxy: true
                      }
                    ])
                  }),
                  _c("ValidatedInput", {
                    attrs: {
                      field: _vm.$v.form.password,
                      value: _vm.$v.form.password.$model,
                      autocomplete: "password",
                      type: "password",
                      "data-cy": "password",
                      translationKey: "login.password"
                    },
                    on: {
                      "update:value": function($event) {
                        return _vm.$set(_vm.$v.form.password, "$model", $event)
                      }
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "prepend-content",
                        fn: function() {
                          return [
                            _c("CIcon", { attrs: { name: "cil-lock-locked" } })
                          ]
                        },
                        proxy: true
                      }
                    ])
                  }),
                  _c(
                    "CRow",
                    [
                      _c(
                        "CCol",
                        { attrs: { md: "6" } },
                        [
                          _c("CButton", {
                            directives: [
                              {
                                name: "t",
                                rawName: "v-t",
                                value: "components.LoginModal.loginButton",
                                expression:
                                  "'components.LoginModal.loginButton'"
                              }
                            ],
                            staticClass: "full-width-button",
                            attrs: {
                              color: "primary",
                              disabled: _vm.loading,
                              type: "submit",
                              "data-cy": "submit"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "CCol",
                        { attrs: { md: "6" } },
                        [
                          _c("router-link", {
                            directives: [
                              {
                                name: "t",
                                rawName: "v-t",
                                value: "components.LoginModal.registerButton",
                                expression:
                                  "'components.LoginModal.registerButton'"
                              }
                            ],
                            staticClass: "btn btn-secondary full-width-button",
                            attrs: {
                              to: { name: "RegisterCustomer" },
                              "data-cy": "forgot-password"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "CCol",
            { staticClass: "mt-3", attrs: { col: "12" } },
            [
              _c("router-link", {
                directives: [
                  {
                    name: "t",
                    rawName: "v-t",
                    value: "components.LoginModal.forgotPasswordButton",
                    expression: "'components.LoginModal.forgotPasswordButton'"
                  }
                ],
                attrs: {
                  to: { name: "ForgotPassword" },
                  "data-cy": "forgot-password"
                }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }